// extracted by mini-css-extract-plugin
export var aEmpty = "La";
export var aTitle = "Ma";
export var pNewsList = "Na";
export var aInner = "Oa";
export var aDate = "Pa";
export var aCategory = "Qa";
export var aButton1 = "Ra";
export var aButton2 = "Sa";
export var aButton3 = "Ta";
export var aButton4 = "Ua";
export var aButton5 = "Va";
export var aButtonHeaderLogin = "Wa";
export var aButtonHeaderRegister = "Xa";
export var aButtonBig = "Ya";
export var aButtonRegister = "Za";
export var aButtonLogin = "_a";
export var aButtonContinuation = "ab";
export var icon = "bb";
export var aButtonHeaderSpLogin = "cb";
export var aArrow1 = "db";
export var aArrow2 = "eb";
export var aText = "fb";
export var animationMarquee = "gb";