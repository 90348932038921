import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss} from "@layouts/Section"
import * as css from "@css/blocks/b-index-hero.module.styl"
import { TransitionLink } from "../atoms/Link"
import Border from "@atoms/Border"
import { NewsList } from "@projects/NewsList"

export default function IndexAbout()
{
    const { news } = useStaticQuery(graphql`
          query {
            news :allWpNews(
                sort: { fields: date, order: DESC }
                limit: 5
            ) {
                edges {
                    node {
                        ...News
                    }
                }
            }
        }
    `)
    return(
        <Section id="news">
            <SectionHeader
                title="News"
                text="ニュース"
            />
            <SectionContent>
                <NewsList data={news.edges}/>
            </SectionContent>
            <SectionMoreLink
                title="More"
                text="もっとみる"
                to="/news/"
            />
        </Section>
    )
}
