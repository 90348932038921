import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
// import { MinScreen } from "@hooks/useMediaQuery"
import * as css from "@css/blocks/b-index-hero.module.styl"
import Logo3D from "@atoms/Logo3D"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "@css/vender/slick-theme.css";


export default function IndexHero()
{
    const q = useStaticQuery(graphql`
        query {
            sp_kv : file(relativePath: { eq: "Sp__IndexHero__img__kv.jpg" }) {
                publicURL
            }
            logo : file(relativePath: { eq: "IndexHero__logo__love-peace.png" }) { childImageSharp {
                ...Thumbnail
            } }

            kv : allWpKv{
                edges {
                    node {
                        acf_kv {
                            imageSp  {
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 1280
                                            placeholder: BLURRED
                                            formats: [AUTO, WEBP]
                                            quality: 100
                                        )
                                    }
                                }
                            }
                            image {
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 1280
                                            placeholder: BLURRED
                                            formats: [AUTO, WEBP]
                                            quality: 100
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const settings = {
        dots: false,
        infinite: true,
        speed: 3000,
        autoplaySpeed: 6000,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,//max-width用
        centerPadding: 0,//variablewidthと競合
        arrows: false,
        autoplay: true,
        fade: true,
    }

    return(
        <section className={css.bIndexHero} id="hero">
            <div className={css.bIndexHero__slider}>
                <Slider {...settings}>
                    {
                        q.kv.edges.map(({ node }, i)=>{
                            let { acf_kv } = node
                            return(
                                <div className={css.bIndexHero__item} key={i}>
                                    { acf_kv.imageSp ?
                                        <>
                                            <div className={css.aBgSp} style={{ backgroundImage: `url(${acf_kv.imageSp.localFile.publicURL})` }} />
                                            <div className={css.aBgPc} style={{ backgroundImage: `url(${acf_kv.image.localFile.publicURL})` }} />
                                        </>
                                        :
                                        <div className={css.aBg} style={{ backgroundImage: `url(${acf_kv.image.localFile.publicURL})` }} />
                                    }
                                </div>
                            )
                        })
                    }
                    {/* <div className={css.bIndexHero__item}>
                        <MinScreen type="tb-sp">
                            <div className={css.aBg} style={{ backgroundImage: `url(${q.sp_kv.publicURL})` }} />
                        </MinScreen>
                        <MinScreen type="pc">
                            <div className={css.aBg} style={{ backgroundImage: `url(${q.kv.publicURL})` }} />
                        </MinScreen>
                    </div> */}
                </Slider>
            </div>
            <div className={css.bIndexHero__inner}> 
                <h1 className={css.bIndexHero__logo}>
                    <Logo3D />
                </h1>
            </div>
            {/* <div className={css.bIndexHero__inner}> 
                <h1 className={css.bIndexHero__logo}>
                    <Image data={q.logo} alt="LOVE & PEACE YU SHIROTA"/>
                </h1>
            </div> */}
        </section>
    )
}
