import React from "react"
import { gsap } from "gsap"
import { graphql, useStaticQuery } from "gatsby"
import { CustomEase } from 'gsap/CustomEase'
import { Image } from "@atoms/Image"
// import { useEventListener } from '@hooks/useEventListener'

import D3SVG01 from "@images/IndexKv__img__3d-01.svg"
import D3SVG02 from "@images/IndexKv__img__3d-02.svg"
import D3SVG03 from "@images/IndexKv__img__3d-03.svg"
import D3SVG04 from "@images/IndexKv__img__3d-04.svg"

import * as css from "@css/atoms/a-logo-3d.module.styl"

gsap.registerPlugin(CustomEase);

export default function Logo3D()
{
    const q = useStaticQuery(graphql`
        query {
            img1 : file(relativePath: { eq: "IndexKv__img__3d-01.svg" }) {
                publicURL
            }
            img2 : file(relativePath: { eq: "IndexKv__img__3d-02.svg" }) {
                publicURL
            }
            img3 : file(relativePath: { eq: "IndexKv__img__3d-03.svg" }) {
                publicURL
            }
            img4 : file(relativePath: { eq: "IndexKv__img__3d-04.svg" }) {
                publicURL
            }
        }`)
    return(
        <div className={css.container}>
            <div className={css.cube}>
                {/* <div><D3SVG01/></div>
                <div><D3SVG04/></div>
                <div></div>
                <div></div>
                <div><D3SVG03/></div>
                <div><D3SVG02/></div> */}
                <div><img src={q.img1.publicURL} alt="YU SHIROTA"/></div>
                <div><img src={q.img4.publicURL} alt="YU SHIROTA OFFICIAL FANCLUB LOVE&PEACE" /></div>
                <div></div>
                <div></div>
                <div><img src={q.img3.publicURL} alt="LOVE&PEACE"/></div>
                <div><img src={q.img2.publicURL} alt="OFFICIAL FANCLUB"/></div>
            </div>
        </div>
    )
}